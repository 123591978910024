.main-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    padding:0px 20px 0 20px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.react-datepicker-wrapper{
  width:100%
}
.react-datepicker-popper {
  z-index: 2;
}
.react-datepicker-ignore-onclickoutside{
  outline: none;
  border-radius: 3px;
  border: 1px solid black!important;
}
.react-datepicker__navigation--previous{
  outline: none;
}
.react-datepicker__navigation--next{
  outline: none;
}
.bottom-navigation {
    display: flex;
    height: 65px;
    justify-content: space-around;
    -webkit-box-align: center;
    align-items: center;
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    z-index: 100;
    border-top: 1px solid #ddd;
    overflow: hidden;
    padding-bottom: env(safe-area-inset-bottom);
    transition: -ms-transform .2s cubic-bezier(0,0,0.1,1) 0s,-webkit-transform .2s cubic-bezier(0,0,0.1,1) 0s,transform .2s cubic-bezier(0,0,0.1,1) 0s,visibility 0s ease 0s;
    background-color: rgb(255 255 255 / 1);
}
@media (max-width: 1919px){
}
.dropdown .MuiTypography-body1  {
  font-size: 15px;
  line-height: 1;
}
::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1; 
}

::-webkit-scrollbar-thumb {
  background: #888; 
}
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}
@media (min-width: 1369px) and (max-width: 1441px){

  .textfield .MuiGrid-root {
    margin-top: 25px!important;
  }
}
@media (min-width: 1439px){
  .makeStyles-specify-14 .RichEditor-root{
    margin-top: 40px!important;
  }
}
.inputTypeSearch:focus{
    outline: aliceblue;
}
.rc-anchor-alert{
  display: none;
}
.react-tel-input input{
  width: 100%!important;
  height: 48px!important;
}
.MuiSlider-thumb.Mui-disabled {
  display: none;
}
.slick-dots li.slick-active button:before {
  opacity: 1!important;
  color: white!important;
}
.slick-dots li button:before {
  font-size: 8px!important;
}

.MuiButton-root.Mui-disabled {
  color: #ffffff!important;
  background: #bdbdbd;
}
.MuiSlider-thumb {
  margin-top: -3px!important;
}
.MuiDialog-paperScrollPaper::-webkit-scrollbar {
    width: 0px;
}
.MuiRadio-colorPrimary.Mui-checked {
  color: #003CA5!important;
}
.react-tel-input .country-list .country {
  display: flex;
  align-items: center;
}
.active  {
  font-family: Nunito Sans, Roboto, sans-serif;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.11;
  letter-spacing: 0.27px;
  text-align: center;
  color: #172b4d;
  text-decoration: none;
  background-color: #ffffff; 
  padding: 15px;
  border-radius:  7px;
  
}
body{
  background: #f7f7fc;
}
body::-webkit-scrollbar {
    display: none;
}
.MuiChip-label {
  font-size: 18px;
}
.vertical-timeline::before {
  content: '';
  position: absolute;
  width: 8px!important;
  background: #F0F5FF!important;
}
.vertical-timeline-element-content {
  -webkit-box-shadow: none!important;
  box-shadow: none!important;
  margin-left: 46px!important;
}
.vertical-timeline-element {
  margin: 0!important;;
}
a{
  font-family: Nunito Sans, Roboto, sans-serif;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.11;
  letter-spacing: 0.27px;
  text-align: center;
  color: #172b4d;
  text-decoration: none;

  padding: 15px;
  border-radius:  7px;
}
@media (max-width: 1024px){
  .main-container {
    padding:0px 30px;
  }
}
@media (max-width: 576px){
  .main-container {
    padding:0px 18px;
  }
}