

.rowFirstTr > td, .rowSecondTr { 
    background-color: white;
}

.rowFirstTr > td:first-child{ 
    border-top-left-radius: 16px;

}
.rowFirstTr > td:last-child{ 
    border-top-right-radius: 16px;

}

.rowExpandTr > td:first-child{
    border-bottom-left-radius: 16px;
}
.rowExpandTr {
    border-collapse: separate;
    border-spacing: 0px 12px;
}

.rowExpandTr > td:last-child{
    border-bottom-right-radius: 16px;
}

.tdItem {
    color: #425466;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-Height: 25px;
}

.tdValuationItem {
    color: #425466;
    font-size: 14px;
    font-style: normal;
    font-weight: bold !important;
    line-Height: 25px;
}
