#menu::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
#menu {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

#text-screen::-webkit-scrollbar {
  display: none;
}

#text-screen {
  /* scroll-behavior: smooth; */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

@media only screen and (max-width: 600px) {
  #root {
    min-height: unset;
  }
}
