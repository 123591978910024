.RichEditor-root {
    background: #fff;
    border: 1px solid #ddd;
    font-family: 'Georgia', serif;
    font-size: 14px;
  
    padding: 15px;
  }
  
  .RichEditor-editor {
    border-top: 1px solid #ddd;
    cursor: text;
    font-size: 18px;
  }
  
  .RichEditor-editor .public-DraftEditorPlaceholder-root,
  .RichEditor-editor .public-DraftEditor-content {
    margin: 0 -15px -15px;
    padding: 15px;
  }
  
  .RichEditor-editor .public-DraftEditor-content {
    min-height: 100px;
  }
  
  .RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
    display: none;
  }
  
  .RichEditor-editor .RichEditor-blockquote {
    border-left: 5px solid #eee;
    color: #666;
    font-family: 'Hoefler Text', 'Georgia', serif;
    font-style: italic;
    margin: 16px 0;
    padding: 10px 20px;
  }
  
  .RichEditor-editor .public-DraftStyleDefault-pre {
    background-color: rgba(0, 0, 0, 0.05);
    font-family: 'Inconsolata', 'Menlo', 'Consolas', monospace;
    font-size: 16px;
    padding: 20px;
  }
  
  .RichEditor-controls {
    font-family: 'Helvetica', sans-serif;
    font-size: 14px;
    user-select: none;
  }
  
  .RichEditor-styleButton {
    color: #172b4d;
    cursor: pointer;
    margin-right: 10px;
    padding: 2px 0;
    display: inline-block;
  }
  
  .RichEditor-activeButton {
    color: #fff;
    height: 28px;
    border-radius: 4px;
    background-color: #003CA5;
  }