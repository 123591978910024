.slick-dots li.slick-active button:before {
    opacity: .75;
    color: #003CA5!important;
    font-size: 10px!important;
}
.slick-dots li button:before {
    opacity: .75;
    color: #EDF2F7!important;
    font-size: 10px!important;
}
*:focus {
    outline: 0;
    outline: none;
}